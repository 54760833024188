// VSI Customization - starts
import { IHeaderData } from '@msdyn365-commerce-modules/header/dist/types/modules/header/header.data';
import MsDyn365, { Text } from '@msdyn365-commerce/core';
import { OrgUnitLocation } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IHeaderProps } from '../definition-extensions/header.ext.props.autogenerated';
import { IMfrmExtendedStoreSelectorData } from '../views/header.data';
// VSI Customization - ends

/**
 * The header preferred store component
 */
@observer
export class HeaderPreferredStore extends React.PureComponent<IHeaderProps<IHeaderData & IMfrmExtendedStoreSelectorData>> {
    public handleClick = () => {
        // VSI Customization - starts
        MsDyn365.isBrowser && document.body.classList.add('modal-store-locator-toggle');
        const pageURL = MsDyn365.isBrowser && window.location.pathname;
        // @ts-ignore
        if ('utag' in window) {
            // @ts-ignore
            utag.link({
                event: "store-locator-slideout-open",
                event_category: "store locator slideout",
                event_action: "open shelf",
                event_label: `from: ${pageURL}`,
                event_noninteraction: "false"
            });
        }
        return this._findPreferreStoreOnClick(this.props);
        // VSI Customization - ends
    };

    // VSI Customization - starts
    public handleClose = (): void => {
        const {
            data: {
                storeSelectorStateManager: { result: storeSelectorStateManager }
            }
        } = this.props;

        MsDyn365.isBrowser && document.body.classList.remove('modal-store-locator-toggle');
        storeSelectorStateManager?.closeDialog();
    };
    // VSI Customization - ends

    public render(): JSX.Element | undefined {
        const {
            data: {
                storeSelectorStateManager: { result: storeSelectorStateManager }
            },
            slots,
            resources
        } = this.props;
        // VSI Customization - starts
        const isModalOpen =
            (this.props.data.storeSelectorState &&
                this.props.data.storeSelectorState.result &&
                this.props.data.storeSelectorState.result.isModalOpen) ||
            false;


        if (MsDyn365.isBrowser && slots && slots.mfrmStoreSelector && slots.mfrmStoreSelector.length) {
            const mfrmPrefferedStore: OrgUnitLocation = localStorage.getItem('_mfrm__prefferedStore_')
                ? JSON.parse(localStorage.getItem('_mfrm__prefferedStore_') || '')
                : null;
            const sociPrefferedStore: any = localStorage.getItem('_mfrm__prefferedStore_')
                ? JSON.parse(localStorage.getItem('_mfrm__prefferedStore_') || '')
                : null;
            if (mfrmPrefferedStore === undefined) {
                localStorage.removeItem('_msdyn365__preferredStore_');
            }
            const storeCity =
                storeSelectorStateManager && (storeSelectorStateManager.preferredStore && storeSelectorStateManager.preferredStore.StoreId) ===
                (mfrmPrefferedStore && mfrmPrefferedStore.OrgUnitNumber) && mfrmPrefferedStore?.City &&
                resources.mfrmStoreCity?.replace('{city}', `<span>${mfrmPrefferedStore?.City}</span>`) || undefined;
            const sociStoreCity = this.props.app.config.enableSociLocations && sociPrefferedStore?.city &&
                resources.mfrmStoreCity?.replace('{city}', `<span>${sociPrefferedStore?.city}</span>`) || undefined;

            const city = this.props.app.config.enableSociLocations ? sociStoreCity : storeCity;

            return (
                <>
                    {/* VSI Customization - starts */}
                    <button
                        className={`ms-header__preferred-store-btn ${isModalOpen && 'ms-header__preferred-store-btn--open'}  msc-btn`}
                        color='secondary'
                        aria-label={resources.headerPreferredStoreAriaLabel}
                        onClick={isModalOpen ? this.handleClose : this.handleClick}
                    >
                        {/* Replaced with Text componentfor rendering html */}
                        {/* <span className='ms-header__preferred-store-text'>{storeName}</span> */}
                        {
                            <Text
                                className='ms-header__preferred-store-text'
                                tag='span'
                                text={city || resources.headerPreferredStoreText}
                            />
                        }
                    </button>
                    {slots.mfrmStoreSelector}
                    {/* VSI Customization - starts */}
                </>
            );
        }

        return <React.Fragment />;
    }

    private async _findPreferreStoreOnClick(props: IHeaderProps<IHeaderData>): Promise<void> {
        const {
            data: {
                storeSelectorStateManager: { result: storeSelectorStateManager }
            },
            modules
        } = props;
        if (!storeSelectorStateManager) {
            return;
        }
        let storeSelectorId: string = '';
        // VSI Customization - starts
        if (modules && Object.keys(modules).length > 0 && modules.mfrmStoreSelector && modules.mfrmStoreSelector.length > 0) {
            storeSelectorId = modules.mfrmStoreSelector[0].id;
        }
        // VSI Customization - ends
        await storeSelectorStateManager
            .openDialog({
                id: storeSelectorId,
                showMapViewLink: false,
                onLocationSelected: orgUnitLocation => {
                    return Promise.resolve();
                }
            })
            .catch((error: Error) => {
                if (props.telemetry) {
                    props.telemetry.error(error.message);
                    props.telemetry.debug('Unable to set preferred store');
                }
                return;
            });
    }
}

export default HeaderPreferredStore;

